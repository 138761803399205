import { getGenericStore } from './genericStore';
import { URIs } from 'common/const';

const LOCAL_STORAGE_ITEM = 'API_ENDPOINT';

const defaultEndpoint = window.localStorage.getItem(LOCAL_STORAGE_ITEM) || URIs.Default_API;

const apiEndpointStore = Object.create(getGenericStore(defaultEndpoint));
apiEndpointStore.defaultEndpoint = defaultEndpoint;
apiEndpointStore.endpoint = apiEndpointStore.state;
apiEndpointStore.init = function () {
    this.subject.next(this.endpoint);
};
apiEndpointStore.setState = function (newEndpoint) {
    if (newEndpoint !== this.endpoint) {
        this.endpoint = newEndpoint;
        window.localStorage.setItem(LOCAL_STORAGE_ITEM, this.endpoint);
        this.subject.next(this.endpoint);
    }
};

export default apiEndpointStore;