import React, { useState, useEffect, useCallback, memo } from 'react';

// MUI tabs stuff
import PropTypes from 'prop-types';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from "@mui/material/styles";

// CSS
import { css } from "aphrodite";

// VMT styles
import styles from 'components/Styles';

const theme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    color: 'white',
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: "monospace",
                    fontSize: "larger"
                }
            }
        }
    },
    palette: {
        text: {
            primary: '#006FCA',
            secondary: 'white',
        },
    },
});

const a11yProps = (index) => {
    return {
        id: `vmt-tab-${index}`,
        'aria-controls': `vmt-tabpanel-${index}`,
    };
}

const VmtAuxTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vmt-aux-tabpanel-${index}`}
            aria-labelledby={`vmt-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

VmtAuxTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export const VmtTabs = memo((props) => {
    const { activeTabIndex = 0, activeTabTitle = '', onSelect = null, children } = props;
    const [tabIndex, setTabIndex] = useState(activeTabIndex);
    const [tabTitles, setTabTitles] = useState([]);

    useEffect(() => {
        console.log('children', children)
        setTabTitles([...children.map((comp) => comp.props?.title)])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setActiveTabIndex = useCallback((index) => {
        console.log('setActiveTabIndex', index, tabIndex);
        if (index === tabIndex || index >= tabTitles.length) return;
        setTabIndex(index);
        (typeof onSelect === 'function') && onSelect(tabTitles[index]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex, tabTitles]);

    useEffect(() => {
        const newTabIndex = tabTitles.findIndex(title => title === activeTabTitle);
        if (newTabIndex !== -1) { // first priority - activeTabTitle
            setActiveTabIndex(newTabIndex);
        }
        else { // second priority - activeTabIndex
            setActiveTabIndex(activeTabIndex);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabIndex, activeTabTitle/*, setActiveTabIndex*/, tabTitles]);

    const handleTabChange = (event, newTabIndex) => {
        setActiveTabIndex(newTabIndex);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%' }}>
                {/* Tabs */}
                <Box className={css(styles.navBar)}>
                    <MuiTabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="vmt-tabs"
                    >
                        {children.map((comp, index) => {
                            return (
                                <MuiTab key={`vmt-tab-key-${index}`} label={comp.props?.title} {...a11yProps(index)} />
                            )
                        })}
                    </MuiTabs>
                </Box>
                {/* Tab panels */}
                {children.map((comp, index) => {
                    return (
                        <VmtAuxTabPanel key={`vmt-tabpanel-key-${index}`} value={tabIndex} index={index} >
                            {comp}
                        </VmtAuxTabPanel>
                    )
                })}
            </Box>
        </ThemeProvider>
    );
});

VmtTabs.propTypes = {
    children: PropTypes.node,
    activeTabIndex: PropTypes.number,
    activeTabTitle: PropTypes.string,
    onSelect: PropTypes.func,
};