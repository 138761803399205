import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import qs from 'qs';

import AppContext, { api, store } from "./app-context";

import VApp from 'components/VApp';
import IdleMonitor from 'components/IdleMonitor';

import { auth, user as userApi } from 'utils/VApi';
import { apiEndpointStore, useStore } from 'stores';
import LocalRunner from 'components/LocalRunner';

import { URIs } from 'common/const';

const AuthWrapper = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDeletingAccount, setIsDeletingAccount] = useState(false);
    const [, setApiEndpoint] = useStore(apiEndpointStore);
    const [user, setUser] = useState();

    const redirect = (url) => window.location.href = url;

    useEffect(() => {
        if (isAuthenticated && isDeletingAccount) {
            userApi()
                .anonymize()
                .then(({ data }) => {
                    console.log('ANONYMIZED', data);
                    if (data === 'deleted') {
                        console.log('REDIRECT TO IDP - SUCCESS');
                        // redirect to https://myaccount.vavanya.com/?accDeleteStatus=success
                        redirect(`${URIs.IDP}/?accDeleteStatus=success`);
                    }
                    else {
                        console.log('REDIRECT TO IDP - FAIL');
                        // redirect to https://myaccount.vavanya.com/?accDeleteStatus=fail
                        redirect(`${URIs.IDP}/?accDeleteStatus=fail`);
                    }
                })
        }
    }, [isAuthenticated, isDeletingAccount]);

    useEffect(() => {
        let queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        // use API endpoint provided via URL query
        if (queryString.api_endpoint !== undefined) {
            setApiEndpoint(queryString.api_endpoint);
        }
        else {
            window.localStorage.removeItem('API_ENDPOINT');
        }
        // deleteAccount
        queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        if (queryString.deleteAccount !== undefined) {
            setIsDeletingAccount(true);
        }

        auth()
            .tokenRefresh()
            .then(
                (res) => {
                    console.log(res);
                    if (res.status < 399) {
                        setUser(res.data);
                        setIsAuthenticated(true);
                    }
                    setLoading(false);
                });
    }, [setApiEndpoint]);

    const loginWithRedirect = () => {
        auth()
            .login()
            .then(({ data }) => data.redirect && redirect(data.url));
    };

    const logout = () => {
        auth()
            .logout()
            .then(
                (res) => {
                    console.log(res);
                    setIsAuthenticated(false);
                    setLoading(false);
                }
            );
    };

    if (loading) {
        return (
            <LinearProgress />
        );
    }

    return (
        <>
            {!isAuthenticated && (
                loginWithRedirect({})
            )}

            {(!isDeletingAccount && isAuthenticated) && (
                <AppContext.Provider value={{ store, api }}>
                    <VApp
                        LogoutCallback={() => logout()}
                        userObject={user}
                    />
                    <IdleMonitor
                        logout={logout}
                        tokenMaxAge={user.sessionDuration}
                    />
                    <LocalRunner />
                </AppContext.Provider>
            )}
        </>
    );
}

export default AuthWrapper;