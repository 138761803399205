// React
import React, { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ApiIcon from '@mui/icons-material/Api';
import GroupsIcon from '@mui/icons-material/Groups';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LogoutIcon from '@mui/icons-material/Logout';

// VMT
// import styles from './Styles'
import InputModal from 'components/Modals/InputModal';
import AboutModal from 'components/Modals/AboutModal';

import { URIs } from 'common/const';
import { apiEndpointStore, /*addInMenuStore,*/ useStore } from 'stores';

const SxBlackAndWhite = {
    bgcolor: '#282c34',
    color: 'white',
    fontFamily: 'monospace',
    fontSize: 'large'
};

export const HeaderComponent = (props) => {

    const { /*mainOnSelect,*/ logoutCallback, /* onToolboxUpdate, onConvert, currentPage,*/ userObject } = props;
    const [showInputModal, setShowInputModal] = useState(false);
    const [showAboutModal, setShowAboutModal] = useState(false);
    const [apiEndpoint, setApiEndpoint] = useStore(apiEndpointStore);

    // const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    // const handleChange = (event) => {
    //     setAuth(event.target.checked);
    // };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeApiEndpoint = () => {
        setAnchorEl(null);
        setShowInputModal(true);
    };

    const logout = () => {
        setAnchorEl(null);
        logoutCallback();
    };



    return (
        <>
            {/* <ThemeProvider theme={darkTheme}> */}
            <AppBar position="static" sx={{ ...SxBlackAndWhite, height: 'min-content' }} >
                {/* <AppBar position="static" sx={styles.navBar} > */}
                <Toolbar variant="dense" sx={SxBlackAndWhite}>
                    {/* <Toolbar variant="dense" sx={styles.navBar}> */}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => setShowAboutModal(true)}
                    >
                        {/* <MenuIcon /> */}
                        {/* <LogoSVG /> */}
                        {/* <Icon /> */}
                        {/* <Icon classes={{ root: classes.iconRoot }}> */}
                        <img src="/favicon-32x32.png" alt='vavanya logo' />
                        {/* <img src="/favicon-16x16.png" /> */}
                        {/* </Icon> */}
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ ...SxBlackAndWhite, flexGrow: 1 }} >
                        #VMT = () =&gt; &#123;Vavanya - Means - Test&#125;
                    </Typography>
                    <>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Typography variant="h6" component="div" sx={SxBlackAndWhite} >
                                {userObject.userName}
                                &nbsp;
                            </Typography>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem component="a" href={URIs.IDP}>
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                <ListItemText>Account Management</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={changeApiEndpoint}>
                                <ListItemIcon>
                                    <ApiIcon />
                                </ListItemIcon>
                                <ListItemText>API Endpoint</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                component="a"
                                href={`${URIs.Fofum}/login`}
                                target="_blank"
                                rel="noopener"
                            >
                                <ListItemIcon>
                                    <GroupsIcon />
                                </ListItemIcon>
                                <ListItemText>Support Forum</ListItemText>
                            </MenuItem>
                            <MenuItem disabled>
                                <ListItemIcon>
                                    <LiveHelpIcon />
                                </ListItemIcon>
                                <ListItemText>Get Started / FAQ</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText>Log Out</ListItemText>
                            </MenuItem>
                        </Menu>
                    </>
                </Toolbar>
            </AppBar>
            <InputModal
                title='Enter new API endpoint URL'
                show={showInputModal}
                defaultValue={apiEndpoint || ''}
                handleClose={() => setShowInputModal(false)}
                onEnter={(newApiEndpoint) => { if (newApiEndpoint !== apiEndpoint) setApiEndpoint(newApiEndpoint); }}
            />
            <AboutModal
                show={showAboutModal}
                handleClose={() => setShowAboutModal(false)}
            />
        </>
    );
}

// export default HeaderComponent;