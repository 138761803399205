// MUI tabs stuff
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export const VmtTabPanel = (props) => {
    const { children, className } = props;

    return (
        <Box role='tabpanelcontent' className={className}>
            {children}
        </Box>
    );
}

VmtTabPanel.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
};
