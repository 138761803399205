// React
import React, { useRef, useState, useEffect, useCallback } from 'react';

import { /*autorun,*/ reaction } from 'mobx';
// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

// MUI
import { loadCSS } from 'fg-loadcss';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon';
import LinearProgress from '@mui/material/LinearProgress';


import { useSnackbar } from 'notistack';

// CSS
import { css } from "aphrodite";

// Blockly
import Blockly from 'blockly/core';

// VMT Blockly
import BlocklyJS from 'blockly/javascript';
import 'blockly_vmt/generator/generator';

// VMT components
import BrickFactoryComponent from 'components/BrickFactoryComponent';
import BasementComponent from 'components/BasementComponent';
import HeaderComponent from 'components/HeaderComponent';
import CodePrettifier, { vmtLogLanguage, vmtLogTheme } from 'components/CodePrettifier';
import BrickTreeComponent from 'components/BrickTreeComponent';
import ConstructionTreeComponent from 'components/ConstructionTreeComponent';

import HorizontalSplit from './HorizontalSplit';

import { useAppContext } from "app-context";

import { VmtTabs, VmtTabPanel } from 'components/VmtTabs';

// VMT styles
import styles from 'components/Styles';

// VMT common
import { TABS, Components, URIs } from 'common/const'

import {
    apiEndpointStore, blocklyEventStore,
    selectedBrickIDsStore, dataBusStore, useStore
} from 'stores';


// // MUI tabs stuff
// import PropTypes from 'prop-types';
// import MuiTabs from '@mui/material/Tabs';
// import MuiTab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
// import { ThemeProvider, createTheme } from "@mui/material/styles";


// const theme = createTheme({
//     components: {
//         MuiTabs: {
//             styleOverrides: {
//                 root: {
//                     color: 'white',
//                 },
//             },
//         },
//         MuiButtonBase: {
//             styleOverrides: {
//                 root: {
//                     fontFamily: "monospace",
//                     fontSize: "larger"
//                 }
//             }
//         }
//     },
//     palette: {
//         text: {
//             primary: '#006FCA',
//             secondary: 'white',
//         },
//     },
// });


// // END of MUI tabs stuff

const ME = Components.V_APP.key;

export const VApp = (props) => {
    const { api, store } = useAppContext();

    const [isLoaded, setIsLoaded] = useState(false);
    const [basementWorkspace, setBasementWorkspace] = useState(null);
    const basementWorkspaceRef = useRef(null);

    const [jsCode, setJSCode] = useState(null);
    const [wsXML, setWsXML] = useState(null);

    const [leftPanelActiveTab, setlLeftPanelActiveTab] = useState(TABS.CONSTRUCTION_DIR.name);
    // const [rightPanelActiveTab, setlRightPanelActiveTab] = useState(TABS.CODE_JS.key);

    const [isExecuting, setIsExecuting] = useState(false);
    const [playButtonTooltip, setPlayButtonTooltip] = useState('');
    const [playButtonIcon, setPlayButtonIcon] = useState('fa-play')

    const [consoleText, setConsoleText] = useState('');
    const [logContentToScroll, setLogContentToScroll] = useState('');

    const [endpoint] = useStore(apiEndpointStore);
    const [blocklyEvent] = useStore(blocklyEventStore, null);
    const [flyoutBrickIds] = useStore(selectedBrickIDsStore);
    const [dataBus, setDataBusContent] = useStore(dataBusStore);

    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();

    useEffect(() => {
        console.log("useEffect()");

        // NEW IMPL >>>
        const load = async () => {
            try {
                // setLoading(true);
                await api.brick.getAll();
                await api.brickTree.getAll();
                await api.constructionTree.getAll();
                await api.construction.getAll();
            } finally {
                setIsLoaded(true)
                setTimeout(() => resizeWindow(), 1000);
            }
        };
        load();
        // END OF NEW IMPL <<<

        // synchBricks();

        // load Font Awesome
        const node = loadCSS(
            URIs.FontAwesome,
            // Inject before JSS
            document.querySelector('#font-awesome-css') || document.head.firstChild,
        );

        // TODO: did not manage to force update/render the Blockly component;
        // that is workaround which seems to do its job but would need to
        // find the root cause of the incorrect behaviour
        setTimeout(() => resizeWindow(), 1000);
        resizeWindow();

        return () => {
            node.parentNode.removeChild(node);
        };
        // eslint-disable-next-line
    }, []);

    const updateBricksFlyout = useCallback((basementWorkspace) => {
        console.log('updateBricksFlyout');
        console.log('flyoutBrickIds', flyoutBrickIds);
        Blockly.Flyout.prototype.autoClose = true;
        const toolbox = basementWorkspace.getToolbox();
        toolbox.clearSelection();

        const blocks = flyoutBrickIds.map(id => {
            let block = Blockly.utils.xml.createElement('block');
            // block.setAttribute('type', id);
            block.setAttribute('type', 'vmt_brick');
            block.setAttribute('is', 'blockly');
            const brick = store.brick.byId(id);
            let field = Blockly.utils.xml.createElement('field');
            field.setAttribute('name', 'brick_caption');
            field.appendChild(Blockly.utils.xml.createTextNode(brick.name));
            block.appendChild(field);

            let brickData = Blockly.utils.xml.createElement('data');
            brickData.appendChild(Blockly.utils.xml.createTextNode(JSON.stringify({ id: id })));
            block.appendChild(brickData);
            // add controls
            if (brick.controls.length > 0) {
                let fields = brick.controls;
                // find the longest text
                const maxFieldLength = fields.reduce((maxLength, { name }) => { return Math.max(maxLength, name.length) }, 0);
                fields = fields.map(field => { return { ...field, name: field.name.padEnd(maxFieldLength) }; });
                const lastField = fields.slice(-1)[0];
                let controlsBlock = null;
                let currentBlock = null;
                fields.forEach(({ name, id, anchor }) => {
                    let block = Blockly.utils.xml.createElement('block');
                    // add metadata to control block
                    let controlData = Blockly.utils.xml.createElement('data');
                    controlData.appendChild(Blockly.utils.xml.createTextNode(JSON.stringify({ id: id, ID: anchor })));
                    block.appendChild(controlData);

                    if (lastField.name !== name) {
                        block.setAttribute('type', 'vmt_control');
                    }
                    else {
                        block.setAttribute('type', 'vmt_control_last');
                    }
                    block.setAttribute('movable', 'false');
                    block.setAttribute('deletable', 'false');
                    let field = Blockly.utils.xml.createElement('field');
                    field.setAttribute('name', 'CONTROL_NAME');
                    field.appendChild(Blockly.utils.xml.createTextNode(name));
                    block.appendChild(field);
                    if (controlsBlock === null) {
                        currentBlock = controlsBlock = block;
                    }
                    else {
                        let nextContainer = Blockly.utils.xml.createElement('next');
                        nextContainer.appendChild(block);
                        currentBlock.appendChild(nextContainer);
                        currentBlock = block;
                    }
                });
                let controlsContainer = Blockly.utils.xml.createElement('value');
                controlsContainer.setAttribute('name', 'CONTROLS');
                controlsContainer.appendChild(controlsBlock);
                block.appendChild(controlsContainer);
            }
            return block;
        });

        // add blocks to the first item in the toolbox
        const category = toolbox.getToolboxItems()[0];
        category.setSelected(true);
        category.updateFlyoutContents(blocks);
        toolbox.setSelectedItem(category);
    }, [flyoutBrickIds, store.brick]);

    useEffect(() => {
        basementWorkspaceRef.current = basementWorkspace;
    }, [basementWorkspace]);

    useEffect(() => {
        const disposer = reaction(
            () => [...store.brick.bricksById],
            () => {
                console.log('>>> bricksData CHANGED <<<');
                if (basementWorkspaceRef.current) {
                    // update flyout
                    updateBricksFlyout(basementWorkspaceRef.current);
                    // hide flyout
                    const toolbox = basementWorkspaceRef.current.getToolbox();
                    toolbox.clearSelection();
                }
            });

        return () => {
            disposer();
        }
    }, [store, updateBricksFlyout]);

    useEffect(() => {
        switch (dataBus?.to) {
            case Components.BRICK_TREE.key:
                // setlLeftPanelActiveTab(TABS.BRICK_DIR.key);
                console.log('>>!! Components.BRICK_TREE.key <<', TABS.BRICK_DIR.name);
                setlLeftPanelActiveTab(TABS.BRICK_DIR.name);
                resizeWindow();
                break;
            case ME:
                if (dataBus.from === Components.LOCAL_RUNNER.key) {
                    switch (dataBus.content.status) {
                        case 'Error':
                            setConsoleText('[LOCAL RUNNER IS NOT DETECTED]\n');
                            break;
                        case 'Close':
                            setIsExecuting(false);
                            break;
                        case 'Running':
                            setConsoleText(dataBus.content.data);
                            break;
                        case 'Connected':
                            setConsoleText('VMT_CLEAR_EXECUTION_LOG');
                            break;
                        default:
                            break;
                    }
                }
                else if (dataBus.from === Components.BASEMENT.key) {
                    setLogContentToScroll(dataBus.content.blockId);
                }
                break;
            default:
                break;
        }
    }, [dataBus]);

    useEffect(() => {
        /*fa-play fa-spinner fa-spin fa-pulse */
        if (isExecuting) {
            setPlayButtonTooltip('Running');
            setPlayButtonIcon('fa-spinner fa-pulse');
        }
        else {
            setPlayButtonTooltip('Launch current construction');
            setPlayButtonIcon('fa-play');
        }
    }, [isExecuting]);

    useEffect(() => {
        // console.log(blocklyEvent);
        if (blocklyEvent === null ||
            blocklyEvent.blocklyEvent === null ||
            blocklyEvent.blocklyWorkspace === null) return;
        const workspace = blocklyEvent.blocklyWorkspace;
        setBasementWorkspace(workspace);
        // console.log('VApp event', blocklyEvent.blocklyEvent);
        if ((blocklyEvent.blocklyEvent.type === Blockly.Events.BLOCK_DRAG && blocklyEvent.blocklyEvent.isStart === false) ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.FINISHED_LOADING ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.CREATE ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.CHANGE ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.DELETE ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.VAR_RENAME) {
            // console.log('VApp UPDATE code', blocklyEvent.blocklyEvent);
            setJSCode(BlocklyJS.workspaceToCode(workspace));
            const xmlDom = Blockly.Xml.workspaceToDom(workspace);
            setWsXML(Blockly.Xml.domToPrettyText(xmlDom));
            return;
        }
        if (blocklyEvent.blocklyEvent.type === Blockly.Events.VAR_CREATE) {
            console.log('VApp: Blockly.Events.VAR_CREATE', blocklyEvent.blocklyEvent);
            setTimeout(() => {
                const event = blocklyEvent.blocklyEvent;
                if (event.varName === '>attr') {
                    const varNameRe = /^attr(\d+)/i;
                    const varMap = workspace.getVariableMap();
                    const allVarNamres = varMap.getAllVariableNames();
                    console.log('varMap', varMap);
                    console.log('allVarNamres', allVarNamres);
                    const similarNames = allVarNamres.filter(name => name.match(varNameRe) !== null);
                    console.log('similarNames', similarNames);
                    try {
                        if (similarNames.length === 0) {
                            workspace.renameVariableById(event.varId, `attr${similarNames.length + 1}`);
                        }
                        else {
                            similarNames.sort();
                            let lastIndex = similarNames[similarNames.length - 1].match(varNameRe)[1];
                            lastIndex = parseInt(lastIndex) + 1;
                            workspace.renameVariableById(event.varId, `attr${lastIndex}`);
                        }
                    }
                    catch (e) {
                        console.error('Blockly variable rename exception', e);
                    }
                }
            }, 4000);
        }
    }, [blocklyEvent]);

    useEffect(() => {
        console.log(`API endpoint CHANGED '${endpoint}'`);
        // reload the app if API endpoint has changed
        if (endpoint === apiEndpointStore.defaultEndpoint) return;
        window.location.reload();
    }, [endpoint]);


    useEffect(() => {
        console.log('showFlyout');
        if (!basementWorkspace) return;
        console.log('showFlyout', flyoutBrickIds);
        updateBricksFlyout(basementWorkspace);
    }, [basementWorkspace, flyoutBrickIds, updateBricksFlyout]);

    const onExecute = useCallback(() => {
        if (!isExecuting) {
            console.log('RUN');
            setIsExecuting(true);
            setConsoleText('VMT_CLEAR_EXECUTION_LOG');
            setConsoleText('[Connecting to Local Runner ...]\n');
            setDataBusContent({
                to: Components.LOCAL_RUNNER.key,
                from: ME,
                content: {
                    command: 'Run',
                    basementWorkspace
                }
            });
        }
    }, [basementWorkspace, isExecuting, setDataBusContent]);

    const generateCode = () => {
        const code = BlocklyJS.workspaceToCode(basementWorkspace);
        setJSCode(code);
        const xmlDom = Blockly.Xml.workspaceToDom(basementWorkspace);
        var xmlText = Blockly.Xml.domToPrettyText(xmlDom);
        setWsXML(xmlText);

        console.log(code);
    }

    const toolboxToggle = () => {
        // synchBricks();
        // synchBrickTree();
        enqueueSnackbar("Synched with backend!", { variant: 'info', persist: false });
        basementWorkspace.scrollCenter();
        // this.simpleWorkspace.workspace.scrollCenter();
        // this.bfWorkspace.workspace.scrollCenter();
    }

    const resizeWindow = () => {
        setTimeout(() => { //Start the timer
            window.dispatchEvent(new Event('resize'));
        }, 20);
    }

    const selectPage = (event) => {
        // TODO: actions constants MUST be used 
        // instead of hardcoded string 
        switch (event) {
            case "logout":
                props.LogoutCallback();
                break;
            case "user_info":
                // setShowModal(true);
                break;
            default:
            // setPage(event);
        }
        // TODO: did not manage to force update/render the Blockly component;
        // that is workaround which seems to do its job but would need to
        // find the root cause of the incorrect behaviour
        resizeWindow();
    }

    if (isLoaded === false) {
        return (
            <LinearProgress />
        );
    }

    return (
        <Container fluid className={"px-0 " + css(styles.appContainer)}>
            <Row className={"mx-0 " + css(styles.appHeader)}>
                <HeaderComponent
                    mainOnSelect={selectPage}
                    onToolboxUpdate={toolboxToggle}
                    onConvert={generateCode}
                    logoutCallback={props.LogoutCallback}
                    userObject={props.userObject}
                />
            </Row>
            <HorizontalSplit>
                <VmtTabs
                    activeTabTitle={leftPanelActiveTab}
                    onSelect={(key) => {
                        resizeWindow();
                        setlLeftPanelActiveTab(key);
                    }}
                >
                    <VmtTabPanel title={TABS.BRICK_DIR.name} className={css(styles.scrollingPane)}>
                        <BrickTreeComponent />
                    </VmtTabPanel>
                    <VmtTabPanel title={TABS.CONSTRUCTION_DIR.name} className={css(styles.scrollingPane)}>
                        <ConstructionTreeComponent />
                    </VmtTabPanel>
                </VmtTabs>
                <BasementComponent />
                <VmtTabs
                    onSelect={resizeWindow}
                    activeTabIndex={0}
                >
                    <VmtTabPanel title={TABS.CODE_JS.name} className={css(styles.nonScrollingPane)}>
                        <CodePrettifier code={jsCode} contentToScroll={logContentToScroll} />
                        <Tooltip title={playButtonTooltip}>
                            <Fab
                                style={{
                                    position: 'fixed',
                                    top: '8rem',
                                    right: '3rem',
                                }}
                                size="small"
                                color="secondary"
                                aria-label="run"
                                onClick={onExecute}
                            >
                                <Icon baseClassName="fas" className={playButtonIcon} />
                            </Fab>
                        </Tooltip>
                    </VmtTabPanel>
                    {
                        (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                        (<VmtTabPanel title={TABS.XML.name} className={css(styles.nonScrollingPane)}>
                            <CodePrettifier code={wsXML} language={'xml'} />
                        </VmtTabPanel>)
                    }
                    <VmtTabPanel title={TABS.LOG.name} className={css(styles.nonScrollingPane)}>
                        <CodePrettifier
                            //code={consoleText}
                            lineToAppend={consoleText}
                            contentToScroll={logContentToScroll}
                            language={vmtLogLanguage}
                            theme={vmtLogTheme}
                            options={{
                                readOnly: true,
                                lineNumbers: 'off',
                                glyphMargin: false,
                                folding: false,
                                // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
                                lineDecorationsWidth: 0,
                                lineNumbersMinChars: 0,
                                minimap: {
                                    enabled: false,
                                }
                            }}
                        />
                    </VmtTabPanel>
                    <VmtTabPanel title={TABS.BRICK_FACTORY.name} className={css(styles.nonScrollingPane)}>
                        <BrickFactoryComponent />
                    </VmtTabPanel>
                </VmtTabs>
            </HorizontalSplit>
        </Container>
    );
};
