import React from 'react';
import apiEndpointStore from './api_endpoint';
import blocklyEventStore from './blocklyEvent';
import addInMenuStore from './addInMenu';
import selectedBrickIDsStore from './selectedBrickIDs';
import selectedBrickTreeNodeStore from './selectedBrickTreeNode';
import brickFactoryDataStore from './brickFactoryData';
import constructionDataStore from './constructionData'
import brickId2EditStore from './brickId2Edit';
import selectedConstructionInfoStore from './selectedConstructionInfo';
// import updatedBrickStore from './updatedBrick';
// import bricksDataStore from './bricksData';
import dataBusStore from './dataBusStore';

export {
    apiEndpointStore, blocklyEventStore, addInMenuStore,
    selectedBrickIDsStore, selectedBrickTreeNodeStore,
    brickFactoryDataStore, constructionDataStore,
    brickId2EditStore, selectedConstructionInfoStore,
    /*updatedBrickStore, bricksDataStore,*/ dataBusStore
};

const useStore = (store, initialState = store.initialState) => {
    const [state, _setState] = React.useState(initialState);

    const setState = React.useCallback(state => {
        ('setState' in store) && store.setState(state);
    }, [store]);

    React.useEffect(() => {
        const subs = store.subscribe(_setState);
        ('init' in store) && store.init();
        return () => subs && subs.unsubscribe();
    }, [store]);

    return [state, setState];
};

export { useStore };