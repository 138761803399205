import React, { useContext } from "react";
import AppApi from "apis/app";
import AppStore from "stores/app";

export const store = new AppStore();
export const api = new AppApi(store);

interface AppContextType {
  store: AppStore;
  api: AppApi;
}

const AppContext = React.createContext<null | AppContextType>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);
  return context as AppContextType;
};

export default AppContext;