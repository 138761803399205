import React, { memo, useRef } from 'react';
import { useSpring, a, animated } from 'react-spring';
import styled from 'styled-components';
import { useDrop, useDrag } from 'react-dnd';
import { useMeasure, usePrevious } from './helpers';
import * as Icons from './icons';
import styles from '../Styles';
import { TreeActions } from '../../common/const';

const Frame = styled('div')`
//   display: table-caption;
  position: relative;
  padding: 4px 0px 0px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: middle;
  color: white;
  fill: white;
  width: fit-content;
`;

const Node = styled('div')`
  display: block; 
  padding: 0px 4px 2px 4px;
  &:hover {
    background-color: grey;
  }
`;

const Title = styled('span')`
  vertical-align: middle;
`;

const Input = styled('input')`
  vertical-align: middle;
  width: fit-content;
//   display: inline-block;
//   width: -webkit-fill-available;
`;

const Content = styled(animated.div)`
  will-change: transform, opacity, height, display;
  margin-left: 6px;
  padding: 0px 0px 0px 14px;
  border-left: 1px dashed rgba(255, 255, 255, 0.4);
  overflow: hidden;
`;

const toggle = {
    // display: 'inline-block',
    width: '1em',
    height: '1em',
    marginRight: 10,
    cursor: 'pointer',
    verticalAlign: 'middle'
};

function useCombinedRefs(...refs) {
    const targetRef = React.useRef();

    React.useEffect(() => {
        refs.forEach(ref => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        })
    }, [refs]);

    return targetRef;
}

const DRAGGING_ITEM = 'TreeItem';

const TreeItemForwardRef = React.forwardRef(({
    itemData,
    children = [],
    name, ancestors, style,
    onClick = null, //() => { },
    onEditMode = null, //() => { },
    onChange = null, //() => { },
    onContextMenu = null, //() => { },
    onExpandCollapse = null, //() => { },
    isSelected = false,
    defaultOpen = false,
    isInEditMode = false,
    isOpen = defaultOpen,
    noDnD = false }, ref) => {

    children = children.length === 0 ? null : children;
    //const [isOpen, setOpen] = useState(defaultOpen);
    // const [isInEditMode, setEditMode] = useState(false);
    const previous = usePrevious(isOpen);
    const [bind, { height: viewHeight }] = useMeasure();
    const { height, opacity, displayState, /*display,*/ transform } = useSpring({
        from: { height: 0, opacity: 0, display: 'none', displayState: 0, transform: 'translate3d(20px,0,0)' },
        to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0, display: isOpen ? 'block' : 'none', displayState: isOpen ? 1 : 0, transform: `translate3d(${isOpen ? 0 : 20}px,0,0)` }
    });
    const Icon = Icons[`${children ? (isOpen ? 'Minus' : 'Plus') : 'Close'}SquareO`];
    // const MenuButton = Icons['EyeO'];

    const [{ isDragging }, drag] = useDrag({
        item: { name, itemData, type: DRAGGING_ITEM },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                setTimeout(() => { //Start the timer
                    onChange(item, TreeActions.Move, dropResult);
                }, 20);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const noDrag = useRef();

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: DRAGGING_ITEM,
        canDrop: (dropItem) => {
            return (itemData.type !== 'item') &&
                (dropItem.itemData.parent_id !== itemData.id) &&
                (dropItem.itemData.id !== itemData.id) &&
                !ancestors.includes(dropItem.itemData.id);
        },
        drop: () => ({ itemData }),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });
    // const innerRef = React.useRef(null);
    const dropRef = useCombinedRefs(ref, drop);

    const isActive = canDrop && isOver;
    let backgroundColor = styles.appContainer.backgroundColor;
    if (isActive) {
        backgroundColor = '#2e6b43';
    }
    else if (canDrop) {
        backgroundColor = '#615c3b';
    }
    else if (isSelected) {
        backgroundColor = 'darkslategray';
    }

    const onChangeRename = (inputElement) => {
        const newValue = inputElement.value.trim();
        const defaultValue = inputElement.defaultValue.trim();
        if (newValue !== '' && newValue !== defaultValue) {
            const item = { ...itemData, text: newValue }
            onChange(item, TreeActions.Rename);
        }
    }

    return (
        <Frame
            ref={noDnD ? noDrag : drag}
            onContextMenu={(e) => {
                e.preventDefault();
            }}>
            <Node
                id={itemData.id}
                ref={dropRef}
                style={{ backgroundColor }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    onContextMenu && onContextMenu(e.clientX, e.clientY, itemData);
                }}
                onClick={() => onClick && onClick(itemData)}
                onDoubleClick={(e) => {
                    e.stopPropagation();
                    onEditMode && onEditMode({ itemData, isEditMode: true });
                    //setEditMode(true);
                }}>
                <Icon
                    style={{ ...toggle, opacity: children ? 1 : 0.3 }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onExpandCollapse && onExpandCollapse({ itemData, expanded: !isOpen });
                        // setOpen(prevState => {
                        //     onExpandCollapse({ itemData, expanded: !prevState });
                        //     return !prevState;
                        // });
                    }} />
                {!isInEditMode ? (
                    <Title
                        id={itemData.id}
                        style={{ ...style, backgroundColor, opacity: isDragging ? 0.2 : 1 }}
                    >{name}</Title>
                ) : (
                    <Input
                        autoFocus
                        type="text"
                        // value={name}
                        defaultValue={name}
                        onFocus={event => {
                            event.target.focus();
                            event.target.select();
                        }}
                        // onChange={(event) => {
                        //     console.log('onChange', event);
                        //     //setName(name)
                        // }}
                        onBlur={(event) => {
                            console.log('onBlur', event);
                            onEditMode({ itemData, isEditMode: false });
                            // setEditMode(false);
                            onChangeRename(event.target);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === 'Escape') {
                                onEditMode({ itemData, isEditMode: false });
                                // setEditMode(false);
                                event.preventDefault();
                                event.stopPropagation();
                                if (event.key === 'Enter') {
                                    onChangeRename(event.target);
                                }
                            }
                        }}
                    />
                )
                }
                {/* {isSelected && (
                    // '...' three dots button should go here
                    <span style={{float: 'right'}}>
                        <MenuButton 
                            style={{ ...toggle }}
                            onClick={(e) => {
                                e.stopPropagation();
                                console.log(e);
                                onContextMenu(e.clientX, e.clientY, itemData);
                            }} />
                    </span>
                )} */}
            </Node>
            {/* {isOpen && previous !== isOpen && ( */}
            <Content style={{
                display: displayState.interpolate((displ) => displ === 0 ? 'none' : 'block'),
                opacity,
                height: isOpen && previous === isOpen ? 'auto' : height
            }}>
                <a.div style={{ transform }} {...bind} children={children} />
            </Content>
            {/* )} */}
        </Frame>
    );
});

const TreeItem = memo(TreeItemForwardRef);
export default TreeItem;